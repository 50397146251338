import React from "react";
import { CheckIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

const Plans = ({ totalPlans, continueBtn, showProtect = false }) => {
  const { t } = useTranslation();

  return (
    <>
      {totalPlans.map((theCard) => (
        <div
          className="flex flex-col p-6 mx-auto text-center text-white rounded-lg border border-gray-100 shadow  xl:p-8 bgPricingCards w-[18rem]"
          key={theCard.user_type}
        >
          <h3 className="mb-4 text-2xl font-semibold">
            {t(`lang.cards.${theCard.user_type}.${theCard.title}`)}
          </h3>
          <p className="font-light text-gray-500 sm:text-lg mb-4">
            {t(`lang.cards.${theCard.user_type}.${theCard.description}`)}
          </p>

          <ul
            role="list"
            className="mb-8 space-y-4 text- flex-1 content-center self-center"
          >
            {theCard.featureKey.map((feature, index) => (
              <li className="flex items-center space-x-3" key={index}>
                <svg
                  className="flex-shrink-0 w-5 h-5 text-green-500 "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                <span>{t(`lang.cards.${theCard.user_type}.${feature}`)}</span>
              </li>
            ))}
          </ul>
          <button
            className="bg-white rounded-full py-2 pl-8 pr-2 flex items-center justify-between"
            onClick={() =>
              continueBtn({
                plan: theCard.user_type,
                priceId: theCard.priceId,
              })
            }
          >
            {showProtect ? (
              <p className="text-black">
                {t(
                  `lang.cards.${theCard.user_type}.${theCard.upgrade_button_text}`
                )}
              </p>
            ) : (
              <p className="text-black">
                {t(`lang.cards.${theCard.user_type}.${theCard.button_text}`)}
              </p>
            )}

            <div className="p-2 bg-[#13072d] rounded-full">
              <ArrowRightIcon className="w-5" />
            </div>
          </button>
        </div>
      ))}
    </>
  );
};

export default Plans;
